import request, {
  SERVICE_MODULE_MAP
} from "@/utils/request";

//发布求购
export function publishNeedApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/enquiry/demand-unfound/publish",
    method: "post",
    data
  });
}

//求购删除/完成/重发
export function operateNeedApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/enquiry/demand-unfound/operate",
    method: "post",
    data
  });
}

//求购列表
export function needListApi(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/enquiry/demand-unfound/list",
    method: "get",
    params
  });
}

//我的求购
export function myNeedApi(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/enquiry/demand-unfound/my",
    method: "get",
    params
  });
}
