<template>
  <div class="screening">
    <!--   查询条件   -->
    <div class="flex px-8 pb-4">
      <span>查询条件：</span>
      <span v-if="keyword" class="keyword">{{ `"${keyword}"` }}</span>
      <div v-for="i in 5" :key="i">
        <div
          v-if="showAlreadySelected('area' + i)"
          class="mr-1 display-flex flex-center"
        >
          <div
            :style="{
              minWidth: query.nameWidth['area' + i] + 'px',
            }"
          >
            {{ query.name["area" + i] }}：
          </div>
          <el-tag
            size="mini"
            closable
            @close="closeTag('area' + i)"
            class="m-r-5 flex-center selected-condition-tag"
          >
            <div
              class="text-show-one"
              :style="{
                maxWidth: 220 - query.nameWidth['area' + i] + 'px',
              }"
            >
              {{ showAlreadySelectedValue("area" + i) }}
            </div>
          </el-tag>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <!--   同省市   -->
    <div class="flex info-item px-8 py-4 justify-between items-center">
      <div class="flex">
        <el-checkbox v-model="query.hasSelected.thisProvince">
          <span>同省市</span>
        </el-checkbox>

        <el-checkbox v-model="query.hasSelected.hasStock">
          <span>有货</span>
        </el-checkbox>
        <el-checkbox v-model="query.hasSelected.hasJoinAlliance">
          <span>已加入的联盟</span>
        </el-checkbox>
        <el-checkbox v-model="query.hasSelected.followStore">
          <span>关注的店铺</span>
        </el-checkbox>
        <el-checkbox v-model="query.hasSelected.priceOpen">
          <span>仅查询已开放价格的商家</span>
        </el-checkbox>
      </div>
      <!-- 联盟、城市、品质 按钮 -->
      <div class="flex items-center label_btns">
        <span
          v-for="(item, index) in labelBtns"
          :class="['label_btn', item.selected ? 'active' : '']"
          @click="showFn(item)"
          :key="index"
        >
          {{ item.name }}
          <i
            :class="[item.selected ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"
          ></i>
        </span>
      </div>
    </div>
    <template v-if="showList">
      <!--   本地汽配城   -->
      <div
        v-if="!query.multiple.area1 && query.multiple.area1Show"
        class="flex flex-center info-item px-8 py-4 item-border-top"
      >
        <div>
          <span>本地汽配城：</span>
        </div>
        <div class="flex1">
          <el-radio-group
            v-model="query.hasSelected.area1Single"
            class="text-show-one"
          >
            <el-radio
              v-for="(item, i) in query.data.area1List"
              :key="i"
              :label="item"
            >
              {{ item }}
            </el-radio>
          </el-radio-group>
        </div>
        <div
          @click="changeMultiple('area1', true)"
          class="border multiple-selection ml-2"
        >
          <i class="el-icon-plus"></i>
          <span class="m-l-5">多选</span>
        </div>
      </div>
      <div
        v-if="query.multiple.area1"
        class="info-item px-8 py-4 item-border-top"
      >
        <div>
          <span>本地汽配城：</span>
        </div>
        <div class="pl-5 ml-4" style="margin-top: -20px">
          <el-checkbox-group v-model="query.select.area1BeforeMultiple">
            <el-checkbox
              v-for="(item, index) in query.data.area1List"
              :key="index"
              :label="item"
              :disabled="setCheckboxDisabled('area1', item)"
              class="mt-1"
            ></el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="flex flex-center mt-4">
          <el-button
            type="primary"
            plain
            size="mini"
            @click="query.multiple.area1 = false"
          >
            取消
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="confirmMultiple('area1')"
          >
            确定
          </el-button>
        </div>
      </div>
      <!--   城市   -->
      <div
        v-if="!query.multiple.area2 && query.multiple.area2Show"
        class="info-item px-8 py-4 item-border-top"
      >
        <div class="flex flex-center">
          <div>
            <span>城市：</span>
          </div>
          <div class="flex1">
            <el-radio-group
              v-model="query.hasSelected.area2Single"
              class="text-show-one"
            >
              <el-radio
                v-for="(item, i) in query.data.area2List"
                :key="i"
                :label="item"
              >
                {{ item }}
              </el-radio>
            </el-radio-group>
          </div>
          <div
            @click="changeMultiple('area2', true)"
            class="border multiple-selection ml-2"
          >
            <i class="el-icon-plus"></i>
            <span class="m-l-5">多选</span>
          </div>
        </div>
      </div>
      <div
        v-if="query.multiple.area2 && !query.multiple.area3"
        class="info-item px-8 py-4 item-border-top"
      >
        <div class="flex">
          <span>城市：</span>
          <el-radio-group v-model="query.select.data2" style="margin-top: -2px">
            <el-radio label="热门城市">
              <span @mouseover="mouseoverTag(-1, 'area2', '热门城市')">
                热门城市
              </span>
            </el-radio>
            <el-radio label="全部城市">
              <span @mouseover="mouseoverTag(-2, 'area2', '全部城市')">
                全部城市
              </span>
            </el-radio>
            <el-radio
              v-for="(item, i) in query.data.area2Category"
              :key="i"
              :label="item.group"
            >
              <span @mouseover="mouseoverTag(i, 'area2', item.group)">
                {{ item.group }}
              </span>
            </el-radio>
          </el-radio-group>
        </div>
        <div class="pl-4">
          <el-checkbox-group v-model="query.select.area2BeforeMultiple">
            <el-checkbox
              :label="item"
              v-for="(item, i) in query.data.area2CurrentList"
              :key="i"
              :disabled="setCheckboxDisabled('area2', item)"
              class="mt-1"
            ></el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="flex flex-center mt-4">
          <el-button
            type="primary"
            plain
            size="mini"
            @click="changeMultiple('area2', false)"
          >
            取消
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="confirmMultiple('area2')"
          >
            确定
          </el-button>
        </div>
      </div>
      <!--   汽配城   -->
      <div v-if="query.multiple.area3">
        <div class="info-item px-8 py-4 item-border-top">
          <div>
            <span>汽配城：</span>
            <el-radio-group v-model="query.select.data3">
              <el-radio label="全部">
                <span @mouseover="mouseoverTag(-1, 'area3', '全部')">
                  全部
                </span>
              </el-radio>
              <el-radio
                v-for="(item, i) in query.data.area3Category"
                :key="i"
                :label="item.group"
              >
                <span @mouseover="mouseoverTag(i, 'area3', item.group)">
                  {{ item.group }}
                </span>
              </el-radio>
            </el-radio-group>
          </div>
          <div class="pl-5 ml-1">
            <el-checkbox-group v-model="query.select.area3BeforeMultiple">
              <el-checkbox
                :label="item"
                v-for="(item, i) in query.data.area3CurrentList"
                :key="'area3-checkbox' + i"
                :disabled="setCheckboxDisabled('area3', item)"
                class="mt-1"
              ></el-checkbox>
              <template v-if="query.select.data3 !== '全部'">
                <el-checkbox
                  :label="item"
                  v-for="(item, i) in query.data.area3CurrentListOther"
                  :key="'area3-checkbox-other' + i"
                  :disabled="setCheckboxDisabled('area3', item)"
                  class="mt-1"
                ></el-checkbox>
              </template>
              <span v-if="query.select.data3 !== '全部'">
                <el-link
                  v-if="!query.data.area3CurrentListOther.length"
                  @click="showOtherData"
                  type="primary"
                  :class="query.data.area3CurrentList.length ? 'm-t--10' : ''"
                >
                  <span>其他汽配城</span>
                  <i class="el-icon-arrow-down m-l-5"></i>
                </el-link>
                <el-link
                  v-else
                  @click="hideOtherData"
                  type="primary"
                  class="m-t--10"
                >
                  <span>收起</span>
                  <i class="el-icon-arrow-up m-l-5"></i>
                </el-link>
              </span>
            </el-checkbox-group>
          </div>
          <div class="flex flex-center mt-4">
            <el-button
              type="primary"
              plain
              size="mini"
              @click="changeMultiple('area3', false)"
            >
              取消
            </el-button>
            <el-button
              type="primary"
              size="mini"
              @click="confirmMultiple('area3')"
            >
              确定
            </el-button>
          </div>
        </div>
      </div>
      <!--   品质   -->
      <div
        v-if="!query.multiple.area4 && query.multiple.area4Show"
        class="flex flex-center info-item px-8 py-4 item-border-top"
      >
        <div>
          <span>品质：</span>
        </div>
        <div class="flex1">
          <el-radio-group
            v-model="query.hasSelected.area4Single"
            class="text-show-one"
          >
            <el-radio
              v-for="(item, i) in query.data.area4List"
              :key="i"
              :label="item"
            >
              {{ item }}
            </el-radio>
          </el-radio-group>
        </div>
        <div
          @click="changeMultiple('area4', true)"
          class="border multiple-selection ml-2"
        >
          <i class="el-icon-plus"></i>
          <span class="m-l-5">多选</span>
        </div>
      </div>
      <div
        v-if="query.multiple.area4"
        class="info-item px-8 py-4 item-border-top"
      >
        <div>
          <span>品质：</span>
        </div>
        <div class="pl-5" style="margin-top: -20px">
          <el-checkbox-group v-model="query.select.area4BeforeMultiple">
            <el-checkbox
              v-for="(item, index) in query.data.area4List"
              :key="index"
              :label="item"
              :disabled="setCheckboxDisabled('area4', item)"
              class="mt-1"
            ></el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="flex flex-center mt-4">
          <el-button
            type="primary"
            plain
            size="mini"
            @click="changeMultiple('area4', false)"
          >
            取消
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="confirmMultiple('area4')"
          >
            确定
          </el-button>
        </div>
      </div>
      <!--   联盟   -->
      <div
        v-if="!query.multiple.area5 && query.multiple.area5Show"
        class="flex flex-center info-item px-8 py-4 item-border-top"
      >
        <div>
          <span>已加入联盟：</span>
        </div>
        <div class="flex1">
          <el-radio-group
            v-model="query.hasSelected.area5Single"
            class="text-show-one"
          >
            <el-radio
              v-for="(item, i) in query.data.area5List"
              :key="i"
              :label="item"
            >
              {{ item }}
            </el-radio>
          </el-radio-group>
        </div>
        <div
          @click="changeMultiple('area5', true)"
          class="border multiple-selection ml-2"
        >
          <i class="el-icon-plus"></i>
          <span class="m-l-5">多选</span>
        </div>
      </div>
      <div
        v-if="query.multiple.area5"
        class="info-item px-8 py-4 item-border-top"
      >
        <div>
          <span>已加入联盟：</span>
        </div>
        <div class="pl-5 ml-4" style="margin-top: -20px">
          <el-checkbox-group v-model="query.select.area5BeforeMultiple">
            <el-checkbox
              v-for="(item, index) in query.data.area5List"
              :key="index"
              :label="item"
              :disabled="setCheckboxDisabled('area5', item)"
              class="mt-1"
            ></el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="flex flex-center mt-4">
          <el-button
            type="primary"
            plain
            size="mini"
            @click="changeMultiple('area5', false)"
          >
            取消
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="confirmMultiple('area5')"
          >
            确定
          </el-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { getGoodsOptionsApi } from "@/api/home/goods";

export default {
  name: "QueryFilter",
  props: {
    keyword: String,
    isShowFilterList: Boolean,
  },
  data() {
    return {
      showList: false,
      labelBtns: [
        {
          name: "城市",
          selected: false,
        },
        {
          name: "品质",
          selected: false,
        },
        {
          name: "联盟",
          selected: false,
        },
      ],
      data1: false,
      data2: true,
      data3: false,
      data4: false,
      data5: false,
      query: {
        select: {
          data1: "",
          data2: "热门城市",
          data3: "A",
          data3Index: 1,
          data4: "",
          data5: "", //联盟

          area1BeforeMultiple: [],
          area2BeforeMultiple: [],
          area3BeforeMultiple: [],
          area4BeforeMultiple: [],
          area5BeforeMultiple: [], //联盟
        },
        hasSelected: {
          area1Single: "", // 单选
          area2Single: "", // 单选
          area3Single: "", // 单选
          area4Single: "", // 单选
          area5Single: "", // 单选
          area1Multiple: [], // 多选
          area2Multiple: [], // 多选
          area3Multiple: [], // 多选
          area4Multiple: [], // 多选
          area5Multiple: [], // 多选

          thisProvince: false, // 同省市
          hasStock: true, // 有货
          hasJoinAlliance: false, // 已加入的联盟
          followStore: false, // 关注的店铺
          priceOpen: false, // 仅查询已开放价格的经销商
        },
        multiple: {
          area1: false,
          area2: false,
          area3: false,
          area4: false,
          area5: false,
          area1Show: true,
          area2Show: true,
          area3Show: true,
          area4Show: false,
          area5Show: false,
        },
        name: {
          area1: "本地汽配城",
          area2: "城市",
          area3: "汽配城",
          area4: "品质",
          area5: "联盟",
        },
        // 名称最小宽度
        // tag-value的最大宽度 = (960-80)/4 - nameWidth
        nameWidth: {
          area1: 84,
          area2: 42,
          area3: 56,
          area4: 42,
          area5: 42,
        },
        data: {
          area1List: [],
          area2List: [],
          area3List: [],
          area4List: [],
          area5List: [],
          area1Category: [],
          area2Category: [],
          area3Category: [],
          area4Category: [],
          area5Category: [],
          area1CurrentList: [],
          area2CurrentList: [],
          area3CurrentList: [],
          area4CurrentList: [],
          area5CurrentList: [],
          area3CurrentListOther: [],
        },
      },
      dataInfo: {},
      isCollapse: false,
    };
  },
  watch: {
    // 城市单选时，显示汽配城
    "query.hasSelected.area2Single": {
      handler(value) {
        if (value && value.length) {
          this.setAutoPartsCity();
        }
      },
    },
    //监听已选中的过滤条件
    "query.hasSelected": {
      handler(value) {
        if (value) {
          this.confirm();
        }
      },
      deep: true,
    },
    isShowFilterList: {
      handler(value) {
        this.showList = value;
        if (!value) {
          this.labelBtns.forEach((p) => {
            p.selected = false;
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.goodsOptions();
    this.showList = this.isShowFilterList;
  },
  methods: {
    showFn(item) {
      item.selected = !item.selected;
      this.labelBtns.forEach((p) => {
        if (p.name !== item.name) {
          p.selected = false;
        }
      });
      let flag = this.labelBtns.every((item) => item.selected == false);
      console.log("flag", flag);

      if (item.name == "城市") {
        if (!this.query.multiple.area2 && !this.query.multiple.area2Show) {
          this.changeMultiple("area2", false);
        } else {
          this.changeMultiple("area2", item.selected);
        }
      }
      if (item.name == "品质") {
        if (!this.query.multiple.area4 && !this.query.multiple.area4Show) {
          this.changeMultiple("area4", false);
        } else {
          this.changeMultiple("area4", item.selected);
        }
      }
      if (item.name == "联盟") {
        console.log("item", item);
        if (!this.query.multiple.area5 && !this.query.multiple.area5Show) {
          this.changeMultiple("area5", false);
        } else {
          this.changeMultiple("area5", item.selected);
        }
      }
      if (!flag && this.showList) {
        return;
      }
      this.showList = !this.showList;
      this.$emit("isShow", this.showList);
    },
    // 获取数据
    goodsOptions() {
      getGoodsOptionsApi().then((res) => {
        // 本地汽配城
        if (res.localAutoPartsCity) {
          this.query.data.area1List = ["全部", ...res.localAutoPartsCity];
        } else {
          res.localAutoPartsCity = [];
        }
        // 热门城市
        if (res.hotCity) {
          res.hotCityName = res.hotCity.map((item) => item.name);
          this.query.data.area2List = res.hotCityName;
          this.query.data.area2CurrentList = this.query.data.area2List;
        } else {
          res.hotCity = [];
        }
        // 城市
        if (res.city) {
          this.query.data.area2Category = res.city;
        } else {
          res.city = [];
        }
        // 汽配城
        if (res.autoPartsCity) {
          this.query.data.area3Category = res.autoPartsCity;
          // 过滤掉其他汽配城
          const list = res.autoPartsCity[0].children.filter((item) => {
            return !item.merge;
          });
          this.query.data.area3CurrentList = list.map((item) => item.name);
          this.query.select.data3 = res.autoPartsCity[0].group;
        } else {
          res.autoPartsCity = [];
        }
        // 品质
        if (res.quality) {
          this.query.data.area4List = ["全部", ...res.quality];
        } else {
          res.quality = [];
        }
        // 联盟
        if (res.alliances) {
          let arr = res.alliances.map((item) => item.name);
          this.query.data.area5List = ["全部", ...arr];
        } else {
          res.alliances = [];
        }
        console.log("this.query", this.query);
        console.log("this.dataInfo", res);
        this.dataInfo = res;
      });
    },
    // 单选城市后，设置汽配城数据
    async setAutoPartsCity() {
      const cityIdsArr = this.getCityIdsArr();
      if (cityIdsArr.length) {
        const data = {
          types: "autoPartsCity",
          cityId: cityIdsArr[0],
        };
        const res = await getGoodsOptionsApi(data);
        if (res && res.autoPartsCity && res.autoPartsCity.length) {
          this.query.select.data3Index = 1;
          this.query.data.area3CurrentListOther = [];
          this.dataInfo.autoPartsCity = res.autoPartsCity;
          // 汽配城 与 goodsOptions 方法设置数据一致
          this.query.data.area3Category = res.autoPartsCity;
          // 过滤掉其他汽配城
          const list = res.autoPartsCity[0].children.filter((item) => {
            return !item.merge;
          });
          this.query.data.area3CurrentList = list.map((item) => item.name);
          this.query.select.data3 = res.autoPartsCity[0].group;

          this.query.multiple.area3 = true;
          this.query.select.data3 = this.dataInfo.autoPartsCity[0].group;
          this.query.select.area3BeforeMultiple = [];
        } else {
          this.dataInfo.autoPartsCity = [];
        }
      }
    },
    // 关闭清空已经选择的数据
    closeTag(type) {
      this.query.hasSelected[type + "Single"] = "";
      this.query.hasSelected[type + "Multiple"] = [];
      this.query.select[type + "BeforeMultiple"] = [];
      this.query.multiple[type] = false;
      if (type === "area2") {
        this.query.multiple.area3 = false;
      }
    },
    confirm() {
      // 查询参数处理
      let autoPartsCityArr = [];
      if (this.query.hasSelected.area1Single) {
        autoPartsCityArr.push(this.query.hasSelected.area1Single);
      }
      autoPartsCityArr = [
        ...autoPartsCityArr,
        ...this.query.hasSelected.area1Multiple,
        ...this.query.hasSelected.area3Multiple,
      ];
      if (autoPartsCityArr.includes("全部")) {
        autoPartsCityArr = [];
      }
      autoPartsCityArr = Array.from(new Set(autoPartsCityArr));

      const cityIdsValue = this.getCityIdsArr();

      let qualityArr = [];
      if (this.query.hasSelected.area4Single) {
        qualityArr.push(this.query.hasSelected.area4Single);
      }
      qualityArr = [...qualityArr, ...this.query.hasSelected.area4Multiple];
      if (qualityArr.includes("全部")) {
        qualityArr = [];
      }
      qualityArr = Array.from(new Set(qualityArr));

      const allianceIdsValue = this.getAllianceIdsArr();

      console.log("hasSelected", this.query.hasSelected);
      const queryData = {
        autoPartsCity: autoPartsCityArr.length
          ? autoPartsCityArr.join(",")
          : "", // # 汽配城，多个值用逗号分隔
        cityIds: cityIdsValue.length ? cityIdsValue : [], // # 区域ID
        quality: qualityArr.length ? qualityArr.join(",") : "", // # 品质，多个值用逗号分隔
        allianceId:
          allianceIdsValue.length || this.query.hasSelected.hasJoinAlliance
            ? allianceIdsValue.join(",")
            : null, // # 联盟IDs，多个使用逗号分割

        thisProvince: this.query.hasSelected.thisProvince, // # 同省市
        hasStock: this.query.hasSelected.hasStock, // # 查有货
        followStore: this.query.hasSelected.followStore, // # 查关注店铺
        priceOpen: this.query.hasSelected.priceOpen, // 仅查询已开放价格的经销商
      };
      // 确定后存储页面数据与条件
      // const data = { data: this._data, queryData: queryData };
      // localStorage.setItem("UBeiSearchCondition", JSON.stringify(data));
      // console.log("query", queryData);
      this.$emit("query", queryData);
    },
    // 多选状态下的确定按钮,设置多选值
    confirmMultiple(type) {
      if (
        type === "area2" &&
        this.query.select.area2BeforeMultiple.length === 1
      ) {
        // 城市多选时，选择一个的情况下才会出现汽配城
        this.setAutoPartsCity();
      } else {
        this.query.multiple[type] = false;
      }
      if (type === "area3") {
        this.query.multiple.area2 = false;
      }
      this.query.hasSelected[type + "Multiple"] = this.query.select[
        type + "BeforeMultiple"
      ];
    },
    // 获取联盟ID
    getAllianceIdsArr() {
      let allianceIdsArr = [];
      let allianceIdsValue = [];
      if (this.query.hasSelected.area5Single) {
        allianceIdsArr.push(this.query.hasSelected.area5Single);
      }
      allianceIdsArr = [
        ...allianceIdsArr,
        ...this.query.hasSelected.area5Multiple,
      ];
      if (
        allianceIdsArr.includes("全部") ||
        this.query.hasSelected.hasJoinAlliance
      ) {
        allianceIdsValue = this.dataInfo.alliances.map((item) => item.value);
      } else {
        // 联盟匹配value
        this.dataInfo.alliances.map((item) => {
          if (allianceIdsArr.includes(item.name)) {
            allianceIdsValue.push(item.value);
          }
        });
      }
      allianceIdsValue = Array.from(new Set(allianceIdsValue));
      return allianceIdsValue;
    },
    // 获取选择的城市ID
    getCityIdsArr() {
      let cityIdsArr = [];
      let cityIdsValue = [];
      if (this.query.hasSelected.area2Single) {
        cityIdsArr.push(this.query.hasSelected.area2Single);
      }
      cityIdsArr = [...cityIdsArr, ...this.query.hasSelected.area2Multiple];
      if (cityIdsArr.includes("全部城市")) {
        cityIdsValue = [];
      } else {
        // 热门城市匹配value
        this.dataInfo.hotCity.map((item) => {
          if (cityIdsArr.includes(item.name)) {
            cityIdsValue.push(item.value);
          }
        });
        // 城市匹配value
        this.dataInfo.city.map((item) => {
          item.children.map((items) => {
            if (cityIdsArr.includes(items.name)) {
              cityIdsValue.push(items.value);
            }
          });
        });
      }
      cityIdsValue = Array.from(new Set(cityIdsValue));
      return cityIdsValue;
    },
    // 多选状态改变逻辑
    changeMultiple(key, value) {
      for (const kyes in this.query.multiple) {
        this.query.multiple[kyes] = false;
      }
      this.query.multiple[key] = value;
    },
    // 类型>类别 鼠标移入
    mouseoverTag(i, type, value) {
      // 城市多选模式数据
      if (type === "area2") {
        this.query.select.data2 = value;
        if (i === -1) {
          this.query.data.area2CurrentList = this.query.data.area2List;
        } else if (i === -2) {
          this.query.data.area2CurrentList = ["全部城市"];
        } else {
          this.query.data.area2CurrentList = this.dataInfo.city[i].children.map(
            (item) => item.name
          );
        }
      }
      // 汽配城多选模式数据
      if (type === "area3") {
        this.query.select.data3 = value;
        this.query.select.data3Index = i;
        this.query.data.area3CurrentListOther = [];
        if (i === -1) {
          this.query.data.area3CurrentList = ["全部"];
        } else {
          /*this.query.data.area3CurrentList = this.dataInfo.autoPartsCity[
            i
          ].children.map(item => item.name);*/
          // 过滤掉其他汽配城
          const list = this.dataInfo.autoPartsCity[i].children.filter(
            (item) => {
              return !item.merge;
            }
          );
          this.query.data.area3CurrentList = list.map((item) => item.name);
        }
      }
    },
    // 控制已经选择的类型
    showAlreadySelected(type) {
      // console.log("query", this.query);
      if (this.query.hasSelected[type + "Single"]) {
        this.query.multiple[type + "Show"] = false;
        return true;
      }
      if (this.query.hasSelected[type + "Multiple"].length) {
        this.query.multiple[type + "Show"] = false;
        return true;
      }
      this.query.multiple[type + "Show"] = true;
      return false;
    },
    // 控制已经选择的数据
    showAlreadySelectedValue(type) {
      if (this.query.hasSelected[type + "Single"] && type !== "area3") {
        return this.query.hasSelected[type + "Single"];
      }
      if (this.query.hasSelected[type + "Multiple"].length) {
        return this.query.hasSelected[type + "Multiple"].join(",");
      }
      return "";
    },
    // 设置多选时的禁用逻辑,最多选择四个
    setCheckboxDisabled(type, item) {
      if (this.query.select[type + "BeforeMultiple"].length >= 4) {
        return !this.query.select[type + "BeforeMultiple"].includes(item);
      }
      return false;
    },
    // 显示其他汽配城
    showOtherData() {
      // 加载其他汽配城
      const list = this.dataInfo.autoPartsCity[
        this.query.select.data3Index
      ].children.filter((item) => {
        return item.merge;
      });
      this.query.data.area3CurrentListOther = list.map((item) => item.name);
    },
    // 隐藏其他汽配城
    hideOtherData() {
      this.query.data.area3CurrentListOther = [];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 20px;
}
::v-deep .el-divider--horizontal {
  margin: 0;
}
::v-deep .el-checkbox {
  margin-right: 15px;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #f56c6c;
}
::v-deep .el-checkbox__inner:hover {
  border-color: #f56c6c;
}
::v-deep .el-radio {
  margin-right: 5px;
  .el-radio__input {
    display: none;
  }
  .el-radio__label {
    padding: 0 5px;
    line-height: 2em;
  }
}
::v-deep .el-radio-group {
  .is-checked {
    .el-radio__label {
      color: #f56c6c;
      border: 1px solid #f56c6c;
      border-radius: 4px;
    }
  }
}
::v-deep .el-checkbox-group {
  max-height: 100px;
  overflow: auto;
}
::v-deep .el-button--primary {
  color: #ffffff;
  background-color: #ff6e4c;
  border-color: #ff6e4c;
  &:hover {
    background: #ff8b70;
    border-color: #ff8b70;
    color: #ffffff;
  }
}
::v-deep .el-button--primary.is-plain {
  color: #ff6e4c;
  background: #fff1ed;
  border-color: #ffc5b7;
  &:hover {
    border-color: #ff6e4c;
    color: #ffffff;
    background-color: #ff6e4c;
  }
}
::v-deep .el-tag {
  background-color: #fff1ed;
  border-color: #ffe2db;
  color: #ff6e4c;
  .el-tag__close {
    color: #ff6e4c;
    &:hover {
      color: #ffffff;
      background-color: #ff6e4c;
    }
  }
}
::v-deep .el-link.el-link--primary {
  color: #ff6e4c;
}
::v-deep .el-link.el-link--primary.is-underline:hover:after {
  border-color: #ff6e4c;
}
.screening {
  color: #333333;
  .keyword {
    color: #ff6e4c;
    margin-right: 10px;
  }
  .label_btns {
    border: 1px solid #999999;
    border-radius: 3px;
  }
  .label_btn {
    padding: 2px 10px;
    border-right: 1px solid #999999;
    cursor: pointer;
    &:hover {
      color: #ff6e4c;
    }
    &:last-child {
      border: none;
    }
    &.active {
      color: #ff6e4c;
    }
  }
  .item-border-top {
    border-top: 1px solid #dcdfe6;
  }
  .border {
    border: 1px solid #999999;
  }
  .selected-condition-tag {
    display: flex;
  }
  .multiple-selection {
    padding: 2px 5px;
    cursor: pointer;
    height: 26px;
    border-radius: 3px;
  }
  .info-item:hover {
    background-color: #ffbb9625;
    // border: 1px solid #fa541c;
  }
  .text-show-one {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    text-overflow: clip !important;
    white-space: normal;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .pl-5 {
    padding-left: 50px !important;
  }
  .ml-4 {
    margin-left: 40px;
  }
  .pl-4 {
    padding-left: 40px !important;
  }
  .mr-1 {
    margin-right: 10px;
  }
}
</style>
