<template>
  <div class=" p-16">
    <div class=" p-8  rounded-lg">
      <el-form ref="form" :model="form" size="small" label-width="80px">
        <el-form-item label="配件信息">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="品质要求">
          <el-select v-model="form.qualities" multiple placeholder="请选择">
            <el-option
              v-for="item in qualitys"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="详细说明">
          <el-input type="textarea" v-model="form.detail"></el-input>
        </el-form-item>
        <el-form-item label="上传图片">
          <UploadFileImg
            :maxSize="1"
            listType="picture-card"
            :limit="4"
            :fileList="imgList"
            :isMultiple="false"
            :showFileList="true"
            :hideFlag="true"
          />
        </el-form-item>
      </el-form>
      <div class=" w-full text-center text-white rounded-full bg-warning py-4 cursor-pointer"  @click="onSubmit">
        发布求购
      </div>
    </div>
  </div>
</template>
<script>
import UploadFileImg from "@/components/Upload/UploadFileImg";
import { publishNeedApi } from "@/api/home/need";
export default {
  props:{
    info:{
      type:Object,
      default:()=>{}
    }
  },
  components: {
    UploadFileImg,
  },
  data() {
    return {
      qualitys: [
        {
          label: "原厂件",
          value: "原厂件",
        },
        {
          label: "品牌件",
          value: "品牌件",
        },
        {
          label: "再制造件",
          value: "再制造件",
        },
        {
          label: "拆车件",
          value: "拆车件",
        },
        {
          label: "其他",
          value: "其他",
        },
      ],
      imgList: [],
      form: {
        title: "",
        detail: "",
        qualities: [],
        pictures: [],
      },
    };
  },
  watch: {
    info:{
      handler: function(newVal){
        const {keyword,quality} = newVal
        this.form.title = keyword
        this.form.qualities = quality ? quality.split(','): []
      },
      deep: true,
    },
    imgList: {
      handler: function(newVal) {
        this.form.pictures = 
          newVal && newVal.length > 0 ? newVal.map(item=> item.origin) : []
      },
      deep: true,
    },
  },
  mounted(){
    const {keyword,quality} = this.info
    this.form.title = keyword
    this.form.qualities = quality ? quality.split(','): []
  },
  methods: {
    onSubmit() {
      if(!this.form.title) {
        this.$message.warning('请输入配件信息')
        return
      }
      publishNeedApi(this.form).then(() => {
        this.$message.success('发布成功!')
        this.form = {
          title: "",
          detail: "",
          qualities: [],
          pictures: [],
        };
        this.imgList = []
        this.$emit('hide')
      });
    },
  },
};
</script>
