<template>
  <div class="width100 page-search">
    <div v-if="queryData.queryType === 1" class="content-box margin-auto">
      <div class="content-box margin-auto flex margin-top3 margin-bottom1">
        <div>
          <img
            class="cursor-pointer logo-img"
            src="@/assets/home/logo@3x.png"
            alt="logo"
            @click="$router.push('/home/index')"
          />
        </div>
        <div class="flex1 margin-left6">
          <div
            class="flex flex-center search-box border-radius6 overflow-hidden background-color27"
          >
            <div
              class="flex flex-center background-color26 padding-left3 padding-right3 padding-top2 padding-bottom2 fontSize14"
              style="margin-left: 2px; border-radius: 20px 0 0 20px;"
            >
              <el-dropdown style="width: 80px" @command="handleChangeQueryType">
                <span class="el-dropdown-link">
                  {{ queryData.queryType === 1 ? "找货" : "找商家" }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="1">找货</el-dropdown-item>
                  <el-dropdown-item :command="2">找商家</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown
                v-if="queryData.queryType === 1"
                style="width: 60px"
                @command="handleChangeExact"
              >
                <span class="el-dropdown-link">
                  {{ queryData.exact ? "精准" : "模糊" }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="false">模糊</el-dropdown-item>
                  <el-dropdown-item :command="true">精准</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <input
              ref="searchInput"
              @keydown.enter="search"
              type="text"
              v-model="keyword"
              placeholder="输入配件名称、OE号、品牌、车型等关键字查询"
              class="search-box-input flex1 padding-left4 padding-right4 padding-top2 padding-bottom2 fontSize14"
            />
            <div
              @click="search"
              class="flex flex-center background-color27 padding-left4 padding-right4 padding-top2 padding-bottom2 fontSize16 textColor10 cursor-pointer"
            >
              <span>搜索</span>
              <img
                class="margin-left1 search-img"
                src="@/assets/home/search.png"
                alt="search"
              />
            </div>
          </div>
          <div
            class="fontSize12 textColor09 overflow-hidden box-sizing padding-left2 padding-right2 margin-top1 text-show-one"
            style="height: 36px;width: 90%;"
            v-if="goodsHistory.length"
          >
            <span
              v-for="(item, index) in goodsHistory"
              :key="index"
              @click="chooseTag(item)"
              class="line-height3 border-radius1 padding1 padding-left2 padding-right2 margin-right2 cursor-pointer actives05 box-shadow5"
            >
              <!-- {{ renderFilter(item) }} -->
              {{ item }}
            </span>
          </div>
          <div style="height: 26px;width: 90%;" v-else></div>
        </div>
      </div>
    </div>

    <div v-if="queryData.queryType === 1" class="width100">
      <!-- 找货 搜索 -->
      <div
        class="conditions-box content-box box-sizing border-radius2 textColor10 queryFilter-box"
      >
        <div class=" flex items-center absolute_btn">
          <div class="item_btn" @click="toGoodsManage">我要共享</div>
          <!-- <div class="item_btn">搜索详情</div> -->
        </div>
        <QueryFilter
          :keyword="keyword"
          :isShowFilterList="isShowFilterList"
          @query="queryChange"
          @isShow="isShowFn"
        />
      </div>

      <!-- 找货 列表 -->
      <div
        v-loading="loading"
        class="min-height500 conditions-box content-box margin-auto margin-bottom6 box-sizing border-radius2 textColor03 min-height300 el-loading-spinners"
      >
        <div v-if="goodsList.length !== 0" class="width100">
          <el-table
            :data="goodsList"
            border
            stripe
            class="table-base"
            @row-click="jump"
            @sort-change="sortChange"
          >
            <el-table-column prop="name" label="配件名称/OE号" width="200">
              <template slot-scope="scope">
                <div>
                  <div
                    :title="scope.row.name"
                    class="fontSize14 textColor23 cursor-pointer actives05 text-show-one"
                  >
                    {{ scope.row.name }}
                  </div>
                  <div class="parts_div">
                    <span
                      class="fontSize13 textColor03  text-show-one"
                      :title="scope.row.code"
                    >
                      {{ scope.row.code }}
                    </span>
                    <i
                      class="el-icon-picture fontSize18 ml-8 picture-logo"
                      v-if="scope.row.images && scope.row.images.length"
                      @click.stop="showImgFn(scope.row.images)"
                    ></i>
                    <span class="is_ti ml-8" v-if="scope.row.isReplacement">
                      替
                    </span>
                    <span
                      class="flag ml-8"
                      style="min-width: 36px;"
                      v-if="scope.row.isInterchange"
                    >
                      平互
                    </span>
                    <span
                      class="flag ml-8"
                      style="min-width: 36px;"
                      v-if="scope.row.isCompanyInterchange"
                    >
                      商互
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="品牌号" width="120">
              <template slot-scope="scope">
                <div class="fontSize14 textColor03 text-show-two">
                  <span>{{ scope.row.productionCode || " " }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="品牌/品质/产地/车型"
              width="200"
            >
              <template slot-scope="scope">
                <div class="fontSize14 textColor03">
                  <span>{{ scope.row.brand || "  " }} | </span>
                  <span> {{ scope.row.quality || "  " }} | </span>
                  <span>{{ scope.row.productionPlace || "  " }}</span>
                  <p class="text-show-one" :title="scope.row.vehModel">
                    {{ scope.row.vehModel }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="companyName" label="供应商" min-width="270">
              <template slot-scope="scope">
                <div class="flex items-center">
                  <div
                    @click.stop="jumpStore(scope.row.companyId)"
                    :title="scope.row.companyName"
                    class="fontSize14 cursor-pointer actives05 mr-8 text-show-one"
                    style="color:#2e69ae;"
                  >
                    {{ scope.row.companyName }}
                  </div>

                  <el-popover placement="bottom" width="300" trigger="click">
                    <!-- 联系人 -->
                    <div>
                      <p v-if="storeContactsInfo.contacts">
                        <span>{{ storeContactsInfo.contacts }}：</span>
                        <span>{{ storeContactsInfo.phone }}</span>
                      </p>
                      <p v-if="storeContactsInfo.contacts1">
                        <span>{{ storeContactsInfo.contacts1 }}：</span>
                        <span>{{ storeContactsInfo.phone1 }}</span>
                      </p>
                      <p v-if="storeContactsInfo.contacts2">
                        <span>{{ storeContactsInfo.contacts2 }}：</span>
                        <span>{{ storeContactsInfo.phone2 }}</span>
                      </p>
                      <p v-if="storeContactsInfo.contacts3">
                        <span>{{ storeContactsInfo.contacts3 }}：</span>
                        <span>{{ storeContactsInfo.phone3 }}</span>
                      </p>
                    </div>
                    <span
                      slot="reference"
                      @click.stop="toContactCompany(scope.row)"
                    >
                      <svg
                        t="1687255816531"
                        class="icon contacts"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="1126"
                        width="20"
                        height="20"
                      >
                        <path
                          d="M875.2 143.5H260.6c-46.8 0-84.8 38-84.8 84.8v63.8h63.6v-42.6c0-23.4 19-42.4 42.4-42.4H854c23.4 0 42.4 19 42.4 42.4v402.6c0 23.4-19 42.4-42.4 42.4h-53.6v63.6h74.8c46.8 0 84.8-38 84.8-84.7V228.3c0-46.8-38-84.8-84.8-84.8z"
                          fill="#2e69ae"
                          p-id="1127"
                        ></path>
                        <path
                          d="M732.1 293.4H145c-44.7 0-81 36.3-81 81v425.1c0 44.8 36.3 81 81 81h587.1c44.7 0 81-36.2 81-81V374.4c0-44.7-36.2-81-81-81z m20.3 485.9c0 22.3-18.1 40.5-40.5 40.5H165.2c-22.4 0-40.5-18.1-40.5-40.5V394.6c0-22.4 18.1-40.5 40.5-40.5h546.7c22.4 0 40.5 18.1 40.5 40.5v384.7z"
                          fill="#2e69ae"
                          p-id="1128"
                        ></path>
                        <path
                          d="M324.2 537.6l-26.5-11.2s-3.9-3.9-4.4-9.3l0.2-3.8c6.2-4.2 10.9-10.6 13.8-18 8.9-0.2 9.1-21.7 4.7-24 1.5-17.5 1.5-47.6-39-48h-0.8c-40.5 0.4-40.5 30.5-39 48-4.4 2.3-4.3 23.8 4.7 24 3 7.5 7.6 13.8 13.8 18l0.2 3.8c-0.6 5.3-4.4 9.3-4.4 9.3L221 537.6c-35.8 20.3-31.9 60.1-31.9 60.1s18.4 20.4 83.3 20.4h0.4c64.9-0.1 83.3-20.4 83.3-20.4s3.9-39.8-31.9-60.1zM443.7 442.5h221.5c14 0 25.3 11.3 25.3 25.2 0 14-11.3 25.3-25.3 25.3H443.7c-14 0-25.3-11.3-25.3-25.3 0.1-13.9 11.4-25.2 25.3-25.2zM443.7 562.8h221.5c14 0 25.3 11.3 25.3 25.3s-11.3 25.3-25.3 25.3H443.7c-14 0-25.3-11.3-25.3-25.3 0.1-14 11.4-25.3 25.3-25.3zM207.8 697.7h457.5c14 0 25.3 11.3 25.3 25.3s-11.3 25.3-25.3 25.3H207.8c-14 0-25.3-11.3-25.3-25.3s11.3-25.3 25.3-25.3z"
                          fill="#2e69ae"
                          p-id="1129"
                        ></path>
                      </svg>
                    </span>
                  </el-popover>
                </div>
                <div class="company_info">
                  <span class="flag flag_green">
                    {{ scope.row.companyAreas[1] }}
                  </span>
                  <span
                    class="flag flag_green m-l-4"
                    v-if="scope.row.autoPartsCity"
                  >
                    {{ scope.row.autoPartsCity }}
                  </span>
                  <span class="flag m-l-4" v-if="scope.row.flagThreesoft">
                    思锐认证
                  </span>
                  <span class="flag m-l-4" v-if="scope.row.isAlliance">
                    联盟
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="价格" prop="price" width="100">
              <template slot-scope="scope">
                <div class="fontSize14  text-align-center">
                  <p
                    v-if="!$store.state.profile.id"
                    @click.stop="$router.push('/login')"
                    class="cursor-pointer textColor21"
                  >
                    登录后查看
                  </p>
                  <div v-else>
                    <p class="textColor03">{{ scope.row.price }}</p>
                    <p
                      style="text-decoration: line-through;"
                      class="textColor09"
                    >
                      {{ scope.row.linePrice }}
                    </p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="库存" width="120">
              <template slot-scope="scope">
                <div class="fontSize14  text-align-center">
                  <p
                    v-if="!$store.state.profile.id"
                    @click.stop="$router.push('/login')"
                    class="cursor-pointer textColor21"
                  >
                    登录后查看
                  </p>
                  <div v-else>
                    <p
                      class="text-show-one"
                      v-for="(stock, index) in scope.row.stockShows"
                      :key="index"
                    >
                      <span class="textColor03">{{
                        `${stock.name ? stock.name : "库存"}：`
                      }}</span>
                      <span>{{ stock.value }}</span>
                    </p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="174">
              <template slot-scope="scope">
                <div class="flex align-items-center fontSize14 textColor03">
                  <span
                    class="actives05 cursor-pointer textColor23 btn"
                    @click.stop="enquiryFn(scope.row)"
                    >询价</span
                  >
                  <span
                    class="actives05 cursor-pointer margin-left1 btn"
                    style="color: #3bba98"
                    @click.stop="sendMsg(scope.row)"
                  >
                    沟通
                  </span>
                  <div
                    class="flex align-items-center fontSize14 textColor03  margin-left1"
                  >
                    <div
                      v-if="!scope.row.collected"
                      @click.stop="collect(scope.row)"
                      class="actives05 cursor-pointer flex flex-center"
                    >
                      <svg
                        t="1667186659379"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="1093"
                        width="16"
                        height="16"
                      >
                        <path
                          d="M908.8 214.4c-9.6-12.8-19.2-22.4-28.8-32-112-115.2-230.4-105.6-342.4-16-9.6 6.4-19.2 16-28.8 25.6-9.6-9.6-19.2-16-28.8-25.6-112-86.4-230.4-99.2-342.4 16-9.6 9.6-19.2 19.2-25.6 32-134.4 195.2-60.8 387.2 137.6 560 44.8 38.4 89.6 73.6 137.6 102.4 16 9.6 32 19.2 44.8 28.8 9.6 6.4 12.8 9.6 19.2 9.6 3.2 3.2 6.4 3.2 12.8 6.4 3.2 3.2 9.6 3.2 16 6.4 25.6 6.4 64 3.2 89.6-12.8 3.2 0 9.6-3.2 16-9.6 12.8-6.4 28.8-16 44.8-28.8 48-28.8 92.8-64 137.6-102.4C969.6 598.4 1043.2 406.4 908.8 214.4zM736 732.8c-41.6 35.2-86.4 70.4-131.2 99.2-16 9.6-28.8 19.2-44.8 25.6-6.4 3.2-12.8 6.4-16 9.6-6.4 3.2-16 6.4-25.6 9.6-3.2 0-6.4 0-9.6 0-6.4 0-12.8 0-16 0-3.2 0-3.2 0-3.2 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0-3.2 0-3.2-3.2-3.2 0-6.4-3.2-9.6-3.2-3.2-3.2-9.6-6.4-16-9.6-12.8-6.4-28.8-16-44.8-25.6-44.8-28.8-89.6-60.8-131.2-99.2-179.2-160-243.2-323.2-131.2-489.6 6.4-9.6 16-16 22.4-25.6 89.6-96 182.4-86.4 275.2-12.8 9.6 6.4 16 12.8 22.4 19.2 0 0 0 0 0 0l28.8 32c3.2 3.2 3.2 3.2 6.4 6.4 0 0 0 0 0 0l0 0c3.2-3.2 9.6-9.6 16-16 12.8-12.8 25.6-25.6 41.6-38.4 92.8-73.6 185.6-83.2 275.2 12.8 6.4 9.6 16 16 22.4 25.6C982.4 406.4 918.4 572.8 736 732.8z"
                          fill="#d81e06"
                          p-id="1094"
                        ></path>
                      </svg>
                      <span style="margin-left:2px;">收藏</span>
                    </div>
                    <div
                      v-else
                      @click.stop="delCollect(scope.row)"
                      class="actives05 cursor-pointer flex flex-center"
                    >
                      <svg
                        t="1667186806096"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="1247"
                        width="16"
                        height="16"
                      >
                        <path
                          d="M892.543016 224.150106c-9.284457-11.914354-17.804505-21.814842-26.454512-30.930453C759.437485 80.827887 642.682341 92.003414 536.033369 176.799682c-9.493212 7.547907-18.453281 15.383362-26.88737 23.346731-8.43409-7.963369-17.395182-15.798824-26.888394-23.346731C375.608633 92.003414 258.853489 80.827887 152.202471 193.21863c-8.650007 9.115612-17.170055 19.016099-25.559119 29.714765C-2.680039 410.134984 68.411089 595.897805 259.728416 764.030084c42.320874 37.192064 87.560218 70.64906 132.799562 99.905384 15.841803 10.245342 30.570249 19.244296 43.816948 26.932396 8.024767 4.657067 13.827937 7.872295 17.044188 9.578146 4.869914 2.916423 9.728572 5.142114 14.530948 6.771217 3.470031 1.619894 7.516184 3.091408 12.218276 4.387937 25.377994 6.998391 62.97938 1.908466 85.839017-11.764951 2.14178-1.101077 7.944949-4.315282 15.969717-8.972349 13.246699-7.688099 27.974122-16.687054 43.816948-26.932396 45.239344-29.256324 90.478687-62.71332 132.799562-99.905384C949.879885 595.897805 1020.971014 410.134984 892.543016 224.150106z"
                          fill="#d81e06"
                          p-id="1248"
                        ></path>
                      </svg>
                      <span style="margin-left:2px;">已收藏</span>
                    </div>
                  </div>
                  <!-- <div
                    class="flex align-items-center fontSize14 textColor03 margin-top2"
                  >
                    <div
                      @click.stop="copyLink(scope.row)"
                      class="actives05 cursor-pointer flex flex-center"
                    >
                      <svg
                        t="1667186930963"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="1401"
                        width="16"
                        height="16"
                      >
                        <path
                          d="M558.933333 699.733333l-170.666666-93.866666c-25.6 21.333333-55.466667 34.133333-89.6 34.133333-72.533333 0-128-55.466667-128-128s55.466667-128 128-128c38.4 0 68.266667 17.066667 93.866666 38.4L597.333333 320V298.666667c0-72.533333 55.466667-128 128-128s128 55.466667 128 128-55.466667 128-128 128c-34.133333 0-64-12.8-85.333333-34.133334l-213.333333 110.933334v21.333333l174.933333 98.133333c21.333333-17.066667 51.2-29.866667 81.066667-29.866666 72.533333 0 128 55.466667 128 128s-55.466667 128-128 128-128-55.466667-128-128c0-4.266667 0-12.8 4.266666-21.333334zM298.666667 554.666667c25.6 0 42.666667-17.066667 42.666666-42.666667s-17.066667-42.666667-42.666666-42.666667-42.666667 17.066667-42.666667 42.666667 17.066667 42.666667 42.666667 42.666667z m426.666666-213.333334c25.6 0 42.666667-17.066667 42.666667-42.666666s-17.066667-42.666667-42.666667-42.666667-42.666667 17.066667-42.666666 42.666667 17.066667 42.666667 42.666666 42.666666z m-42.666666 426.666667c25.6 0 42.666667-17.066667 42.666666-42.666667s-17.066667-42.666667-42.666666-42.666666-42.666667 17.066667-42.666667 42.666666 17.066667 42.666667 42.666667 42.666667z"
                          fill="#F3D146"
                          p-id="1402"
                        ></path>
                      </svg>
                      <span class="margin-left2">分享</span>
                    </div>
                  </div> -->
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div
          v-if="goodsList.length === 0 && isQuery"
          class="width100 flex flex-center flex-direction-column"
        >
          <p class="textColor03 fontSize18 margin-bottom2">
            抱歉,没有找到相关商品
          </p>
          <p
            class="textColor03 fontSize14 margin-bottom2"
          >
            可发布求购和询价，让全国配件商为您服务！
          </p>
        
          <div class="no-searched flex" style="margin-top:40px;">
            <div
              class="flex flex-col  items-center"
              style="margin-right:40px;"
            >
              <div
                @click="enquiry"
                class="background-color27 textColor10 fontSize16 border-radius4 line-height2 padding-left3 padding-right3 cursor-pointer actives05"
              >
                发布求购
              </div>
              <p
                class="textColor03 fontSize14 margin-top2"
              >
                发布到求购专区公开求购。
              </p>
            </div>
            <div class="flex flex-col justify-center items-center">
              <div
                @click="inquiryFn"
                class="background-color27 textColor10 fontSize16 border-radius4 line-height2 padding-left3 padding-right3 cursor-pointer actives05"
              >
                发布询价
              </div>
              <p
                class="textColor03 fontSize14 margin-top2"
              >
                发布询价后平台将推送给匹配的商家进行报价。
              </p>
              <div class="textColor23">（推荐使用）</div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div v-else class="width100">
      <yellow-page
        :isImportTP="true"
        :keywordValue="keyword"
        @switchTP="switchTP"
      />
    </div>

    <el-dialog
      title="发布求购"
      :visible.sync="dialogVisible"
      append-to-body
      width="30%"
    >
      <EnquiryDialog
        v-if="dialogVisible"
        :info="enquiryInfo"
        @hide="dialogVisible = false"
      />
    </el-dialog>

    <fixedNav />
    <div class="demo-image__preview">
      <el-image
        ref="imgpreview"
        style="width: 0px; height: 0px"
        :src="itemUrl"
        :preview-src-list="srcList"
      >
      </el-image>
    </div>
  </div>
</template>
<script>
import yellowPage from "../index/yellowPage.vue";
import QueryFilter from "./components/queryFilter.vue";
import EnquiryDialog from "../components/enquiryDialog.vue";
import { historyLogsListApi } from "@/api/home/log";
import titleIcon1 from "@/assets/home/yellowPage1.png";
import titleIcon2 from "@/assets/home/yellowPage2.png";
import titleIcon3 from "@/assets/home/yellowPage3.png";
import titleIcon5 from "@/assets/home/yellowPage5.png";
import defaultImg from "@/assets/maintenance/default-store.png";
import { getDict, getBrands } from "@/api/basic";
import { createHistoryLogsApi } from "@/api/home/log";
import { getGoodsApi } from "@/api/home/goods";
import { collect, delCollectibleGoodsByGoodsApi } from "@/api/collection";
import { getContactStaffs } from "@/api/home/chat";
import { getCompanyById } from "@/api/home/company";
import { enquiryPublishQuickly } from "@/api/enquiry";
import fixedNav from "@/components/fixedNav/fixedNav";
import eventBus from "@/event";

export default {
  components: {
    yellowPage,
    EnquiryDialog,
    fixedNav,
    QueryFilter,
  },
  data() {
    return {
      titleIcon5,
      defaultImg,
      keyword: "",
      dataList: [
        {
          name: "品牌",
          isMore: true,
          titleIcon: titleIcon1,
          selected: [],
          list: [],
        },
        {
          name: "品质",
          isMore: true,
          titleIcon: titleIcon2,
          selected: [],
          list: [],
        },
        {
          name: "汽配城",
          isMore: true,
          titleIcon: titleIcon3,
          selected: [],
          list: [],
        },
      ],
      queryData: {
        page: 1,
        pageSize: 20,
        keyword: "",
        thisProvince: false,
        // brand: "",
        // vehBrands: "",
        autoPartsCity: "",
        thisCity: false,
        hasStock: true,
        followStore: false,
        exact: false,
        sort: "",
        queryType: 1,
        interReplMode: "all", //互换/替换查询模式，默认不查询 only: 只查互换/替换件 flags: 只标记是否有互换/替换件 all: 查询原配件和互换/替换件，并标记是否有互换/替换件
      },
      goodsHistory: [],
      storeHistory: [],
      selectedList: [],
      selectedName: "",
      goodsList: [],
      loading: false,
      enquiryInfo: {},
      dialogVisible: false,
      isQueryMore: true,
      currentPageSize: 0,
      scrollTop: 0,
      srcList: [],
      storeContactsInfo: {},
      isQuery: false,
      isShowFilterList: true,
    };
  },
  computed: {
    itemUrl() {
      return this.srcList[0] || "";
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.searchInput.focus();
    });
    if (this.$route.query && this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
    if (this.$route.query && this.$route.query.queryType) {
      this.queryData.queryType = Number(this.$route.query.queryType);
    }
    this.initData();
    const app = document.getElementById("app");
    app.addEventListener(
      "scroll",
      this.debounceFn((e) => {
        if (
          this.$router.currentRoute.path === "/home/search" &&
          this.queryData.queryType === 1
        ) {
          this.appScroll(e);
        }
      }, 300),
      true
    );
  },
  activated() {
    if (this.$route.query && this.$route.query.queryType) {
      this.queryData.queryType = Number(this.$route.query.queryType);
    }
  },
  methods: {
    //跳转至商品管理页
    toGoodsManage() {
      this.$router.push("/goodsManage/list?tabName=Parts");
    },
    toContactCompany(row) {
      getCompanyById({
        id: row.companyId,
      }).then((res) => {
        this.storeContactsInfo = res;
      });
    },
    showImgFn(images) {
      this.srcList = images;
      // setTimeout(() => {
      //   document
      //     .querySelector(".demo-image__preview .el-image__preview")
      //     .click();
      // }, 500);
       
          eventBus.$emit("ShowImageViewer", {
          index: 0,
          list: images
        });
    
    },
    // 防抖
    debounceFn(fn, delay) {
      let timer = null;
      return function() {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          // eslint-disable-next-line prefer-rest-params
          fn.apply(this, arguments);
          timer = null;
        }, delay);
      };
    },
    // 滑动加载，距离底部300
    appScroll(e) {
      this.scrollTop = e.target.scrollTop;
      if (this.currentPageSize === this.queryData.pageSize) {
        const pageBox = document.getElementsByClassName("page-box")[0];
        const allHeight = pageBox.offsetHeight;
        if (allHeight - e.target.scrollTop - e.target.offsetHeight < 300) {
          this.setQueryData({
            page: this.queryData.page + 1,
          });
        }
      }
    },
    initData() {
      this.getSearchHistory();
      // this.getBrands();
      // this.getDict();
      // this.getDicts();
      if (this.keyword) {
        this.setQueryData();
      }
    },
    // 获取搜索历史
    getSearchHistory() {
      historyLogsListApi({
        type: "searchGoods",
      }).then((res) => {
        if (res) {
          let resData = res.map((item) => item.keyword);
          this.goodsHistory = Array.from(new Set(resData));
        }
      });
    },
    // 获取品牌
    getBrands() {
      getBrands({}).then((res) => {
        if (!res) {
          this.dataList[0].list = [];
        }
        for (let item of res) {
          item.selected = false;
        }
        this.dataList[0].list = res;
      });
    },
    // 获取品质
    getDict() {
      getDict({
        type: "quality",
      }).then((res) => {
        if (!res) {
          this.dataList[1].list = [];
        }
        for (let item of res) {
          item.selected = false;
        }
        this.dataList[1].list = res;
      });
    },
    // 获取汽配城
    getDicts() {
      getDict({
        type: "autoPartsCity",
      }).then((res) => {
        if (!res) {
          this.dataList[2].list = [];
        }
        for (let item of res) {
          item.selected = false;
        }
        this.dataList[2].list = res;
      });
    },
    // 获取商品列表
    getGoodsList() {
      this.loading = true;
      getGoodsApi(this.queryData)
        .then((res) => {
          if (res.rows) {
            if (this.queryData.page === 1) {
              this.goodsList = res.rows;
            } else {
              this.goodsList = this.goodsList.concat(res.rows);
            }
            this.currentPageSize = res.rows.length;
          } else {
            this.currentPageSize = 0;
            if (this.queryData.page === 1) {
              this.goodsList = [];
            }
          }
          setTimeout(() => {
            this.$nextTick(() => {
              const app = document.getElementById("app");
              app.scrollTop = this.scrollTop;
            });
          }, 0);
        })
        .finally(() => {
          this.loading = false;
          this.isQuery = true;
        });
    },
    // 设置搜索历史
    createHistoryLogsApi() {
      if (this.queryData.keyword) {
        createHistoryLogsApi({
          type: "searchGoods",
          content: {
            keyword: this.queryData.keyword,
            exact: this.queryData.exact,
            hasStock: this.queryData.hasStock,
            followStore: this.queryData.followStore,
            thisProvince: this.queryData.thisProvince,
            quality: this.queryData.quality,
            searchType: this.queryData.exact ? "1" : "0",
          },
        });
      }
    },
    queryChange(query) {
      console.log("query", query);
      this.setQueryData(query);
    },
    isShowFn(val) {
      this.isShowFilterList = val;
      console.log("isShowFilterList", val);
    },
    // 设置查询条件
    setQueryData(query) {
      this.queryData = {
        ...this.queryData,
        keyword: this.keyword,
        page: 1,
        // thisProvince: this.queryData.thisCity,
        // brand: this.dataList[0].selected.join(","),
        // vehBrands: this.dataList[1].selected.join(","),
        // autoPartsCity: this.dataList[2].selected.join(","),
        type: ["dealer"],
        ...query,
      };
      this.createHistoryLogsApi();
      this.getGoodsList();
    },
    switchTP() {
      this.queryData.queryType = 1;
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },
    // 找货/找商家
    handleChangeQueryType(e) {
      this.queryData.queryType = e;
    },
    // 模糊/精准
    handleChangeExact(e) {
      this.queryData.exact = e;
    },
    // 选择历史
    chooseTag(item) {
      this.keyword = item;
      this.isShowFilterList = false;
      this.setQueryData({});
    },
    // 搜索
    search() {
      if (!this.keyword) {
        this.$message.warning("请输入关键字");
        return;
      }
      this.isQueryMore = false;
      this.isShowFilterList = false;
      this.setQueryData({});
      this.getSearchHistory();
    },
    // 搜索
    setKeyword() {
      this.setQueryData({});
    },
    // 分类查看更多
    lookMore(index) {
      this.dataList[index].isMore = !this.dataList[index].isMore;
    },
    // 分类选择
    selected(index, indexs) {
      this.dataList[index].list[indexs].selected = !this.dataList[index].list[
        indexs
      ].selected;
      this.getSelected();
    },
    // 获取选择数据
    getSelected() {
      let arr = [];
      let selectedName = [];
      this.dataList = this.dataList.map((item) => {
        item.selected = [];
        item.list.map((items) => {
          items.selected &&
            arr.push(items) &&
            selectedName.push(items.name) &&
            item.selected.push(items.name);
        });
        return item;
      });
      this.selectedName = selectedName.join(" | ");
      this.selectedList = arr;
      this.setQueryData({});
    },
    // 清空条件
    emptyCondition() {
      this.selectedName = "";
      this.selectedList = [];
      this.dataList = this.dataList.map((item) => {
        item.list.map((items) => {
          items.selected = false;
          return items;
        });
        item.selected = [];
        return item;
      });
      this.queryData = {
        page: 1,
        pageSize: 20,
        keyword: "",
        thisProvince: false,
        // brand: "",
        // vehBrands: "",
        autoPartsCity: "",
        thisCity: false,
        hasStock: false,
        followStore: false,
        exact: false,
        sort: "",
        queryType: 1,
        interReplMode: "all",
      };
      this.setQueryData({});
    },
    // 价格排序
    sortChange(e) {
      this.queryData.sort = `${e.prop}-${e.order.substring(0, 3)}`;
      this.setQueryData();
    },
    // 跳转详情
    jump(data) {
      if (window.getSelection().type === "Caret") {
        // this.$router.push("/home/good?goodId=" + data.id);
        // 跳转详情，新开tab页
        const routeData = this.$router.resolve({
          path: "/home/good",
          query: { goodId: data.id },
        });
        window.open(routeData.href, "_blank");
      }
    },
    // 跳转商家
    jumpStore(id) {
      // 跳转商家，新开tab页
      const routeData = this.$router.resolve({
        path: "/home/store",
        query: { storeId: id },
      });
      window.open(routeData.href, "_blank");
    },
    // 收藏
    collect(item) {
      if (!this.$store.state.profile.id) {
        this.$router.push("/login");
        return;
      }
      collect({
        goodsId: item.swPartId,
        storeId: item.companyId,
      }).then(() => {
        this.setQueryData();
      });
    },
    // 取消收藏
    delCollect(item) {
      if (!this.$store.state.profile.id) {
        this.$router.push("/login");
        return;
      }
      delCollectibleGoodsByGoodsApi({
        companyId: item.companyId, //公司/店铺ID
        swPartId: item.swPartId, //公司/店铺软件内商品ID
        goodsType: 0, //商品类型（0:零配件/1:项目/2:套餐）
      }).then(() => {
        this.setQueryData();
      });
    },
    // 分享
    copyLink(item) {
      const link = `${location.origin}/home/good?goodId=${item.id}`;
      const _this = this;
      this.$copyText(link).then(
        function() {
          _this.$message({
            message: "已复制到剪贴板",
            type: "success",
          });
        },
        function() {
          _this.$message({
            message: "复制失败",
            type: "error",
          });
        }
      );
    },
    //询价
    enquiryFn(row) {
      if (!this.$store.state.profile.id) {
        this.$router.push("/login");
        return;
      }
      if (row.isMine) {
        this.$alert("不能向自己询价！", "温馨提醒", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          showCancelButton: true,
        });
        return;
      }
      this.publishQuickly(row);
    },
    // 发送询价消息
    publishQuickly(row) {
      const data = {
        swPartId: row.swPartId,
        property: row.property,
        offerCompanyId: row.companyId,
        offerUserId: row.contactUsers[0],
      };
      this.loading = true;
      enquiryPublishQuickly(data)
        .then(() => {
          this.loading = false;
          this.$message.success({ message: "发送询价成功！", showClose: true });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 沟通
    sendMsg(item) {
      if (!this.$store.state.profile.id) {
        this.$router.push("/login");
        return;
      }
      getContactStaffs({
        companyId: item.companyId,
      }).then((res) => {
        this.$store.dispatch("setChatId", {
          ...item,
          userId: res[0].userId,
        });
        this.$store.dispatch("changeIMVisible", true);
      });
    },
    // 发布求购
    enquiry() {
      if (!this.$store.state.profile.id) {
        this.$router.push("/login");
        return;
      }
      this.dialogVisible = true;
      this.enquiryInfo = {
        ...this.queryData,
        quality: this.dataList[1].selected.join(","),
      };
    },
    //发布询价
    inquiryFn() {
      if (!this.$store.state.profile.id) {
        this.$router.push("/login");
        return;
      }
      let obj = {
        path: "/home/postEnquiry",
        query: {
          keyword: this.queryData.keyword,
        },
      };
      this.$router.push(obj);
    },
    renderFilter(item) {
      let arr = [];
      // if (item.searchType && item.searchType == "1") {
      //   arr.push("精准");
      // } else if (item.searchType && item.searchType == "0") {
      //   arr.push("模糊");
      // }
      if (item.keyword) {
        arr.push(item.keyword);
      }
      // if (item.thisCity) {
      //   arr.push("同城");
      // }
      // if (item.thisProvince) {
      //   arr.push("同省市");
      // }
      // if (item.hasStock) {
      //   arr.push("有货");
      // }
      // if (item.followStore) {
      //   arr.push("关注店铺");
      // }
      // if (item.onlyPartsOn) {
      //   arr.push("有上架");
      // }
      // if (item.priceOpen) {
      //   arr.push("仅查价格开放的");
      // }
      // if (item.stockOpen) {
      //   arr.push("仅查库存开放的");
      // }
      // if (this.queryType === 1) {
      //   if (item.category) {
      //     let categoryStr =
      //       item.category.length > 10
      //         ? item.category.substr(0, 10) + "..."
      //         : item.category;
      //     arr.push(categoryStr);
      //   }
      //   if (item.partType) {
      //     let partTypeStr =
      //       item.partType.length > 10
      //         ? item.partType.substr(0, 10) + "..."
      //         : item.partType;
      //     arr.push(partTypeStr);
      //   }
      //   if (item.brand) {
      //     let brandStr =
      //       item.brand.length > 10
      //         ? item.brand.substr(0, 10) + "..."
      //         : item.brand;
      //     arr.push(brandStr);
      //   }
      //   if (item.quality) {
      //     let qualityStr =
      //       item.quality.length > 10
      //         ? item.quality.substr(0, 10) + "..."
      //         : item.quality;
      //     arr.push(qualityStr);
      //   }
      //   if (item.autoPartsCity) {
      //     let autoPartsCityStr =
      //       item.autoPartsCity.length > 10
      //         ? item.autoPartsCity.substr(0, 10) + "..."
      //         : item.autoPartsCity;
      //     arr.push(autoPartsCityStr);
      //   }
      // }
      return arr.join(" | ");
    },
    queryMore() {
      this.isQueryMore = !this.isQueryMore;
    },
  },
};
</script>
<style lang="less" scoped>
.page-search .logo-img {
  height: 64px;
}

.page-search .search-img {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}

.page-search .search-box {
  border: 3px solid #ff6e4c;
}

.page-search .search-box-input {
  border: none;
  outline: none;
}

.page-search .conditions-box-title-img {
  width: 16px;
  height: 16px;
}

.page-search {
  .queryFilter-box {
    margin: 0px auto 10px;
    padding: 10px;
    background: #f4f4f4;
    position: relative;
  }
  .absolute_btn {
    position: absolute;
    top: -25px;
    right: 0;
    color: #333333;
    // border: 1px solid #ff6e4c;
    .item_btn {
      padding: 2px 10px;
      background-color: #ff6e4c;
      color: #ffffff;
      // border-right: 1px solid #ff6e4c;
      cursor: pointer;
      &:hover {
        background-color: #ff6e4c;
        color: #ffffff;
      }
      &:last-child {
        // background-color: #999999;
        border: none;
      }
    }
  }
  .picture-logo {
    color: #259bd5;
    cursor: pointer;
  }
  .parts_div {
    display: flex;
    align-items: center;
    // flex-wrap: wrap;
  }
  .is_ti {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    height: 20px;
    background: #f5222e;
    border: 1px solid #f5222e;
    border-radius: 50%;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 600;
    color: #ffffff;
  }
  .flag {
    display: inline-block;
    padding: 0 5px;
    background: #ffffff;
    border: 1px solid#ff6e4c;
    border-radius: 5px;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #ff6e4c;
    line-height: 18px;
  }
  .flag_green {
    color: #229ca6;
    border: 1px solid#229ca6;
  }
  .contacts {
    height: 20px;
    min-width: 20px;
    cursor: pointer;
  }
  // .btn:hover {
  //   color: #ff6e4c;
  // }
  .btn {
    border: 1px solid;
    padding: 0px 5px;
    border-radius: 3px;
  }
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #ff6e4c !important;
  border: #ff6e4c !important;
}
/deep/ .table-base .el-table__row td .cell {
  padding-top: 5px;
  padding-bottom: 5px;
}
/deep/ .el-table th {
  padding: 0 !important;
  background-color: #f0f0f0;
}

/deep/ .el-table__row:hover {
  box-shadow: 0 0 10px #e6e7ea;
}
</style>
